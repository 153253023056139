import React from "react";

import "./TermsAndConditionsContent.module.scss";

export const TermsAndConditionsContent = () => {
  return (
    <div className="terms-and-conditions-content">
      <h1>Piroshky Bakery Terms Of Service</h1>
      <p>
        These Terms of Use (“Terms”) are a legal contract between you (“you”)
        and Piroshky Baking Company, LLC. ("us" or "our" or "we" or “Piroshky
        Bakery”) and govern your use of all the text, data, information,
        software, graphics, photographs and other materials (collectively
        referred to as “Materials”) that we and our affiliates may make
        available to you through any area of the website located at
        http://piroshkybakery.com (“Website”), and any services we may provide
        through the Website (“Services”).
      </p>
      <p>
        READ THESE TERMS CAREFULLY BEFORE BROWSING THE WEBSITE OR OTHERWISE
        USING THE WEBSITE. USING ANY PART OF THE WEBSITE INDICATES THAT YOU HAVE
        BOTH READ AND ACCEPTED THESE TERMS. YOU CANNOT USE THE WEBSITE OR OUR
        SERVICES IF YOU DO NOT ACCEPT THESE TERMS.{" "}
      </p>
      <p>
        <u>
          NOTE: THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION
          PROVISION, INCLUDING CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS
          UNDER THESE TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH
          PIROSHKY BAKERY. YOU MAY OPT OUT OF THE BINDING INDIVIDUAL ARBITRATION
          AND CLASS ACTION WAIVER AS PROVIDED BELOW.
        </u>
      </p>
      <h2>CHANGES.</h2>
      <p>
        We may alter the Materials we offer and/or may choose to modify, suspend
        or discontinue any part or all of the Website or Services at any time
        and without notifying you. We may also change, update, add or remove
        provisions of these Terms from time to time. We will inform you of any
        modifications to these Terms by posting them on the Website and, if you
        have registered with us, by describing the modifications to these Terms
        in an email that we will send to the address associated with your
        account. To be sure we properly reach your email inbox, we just ask that
        you let us know if your preferred email address changes at any time
        after your registration.
      </p>
      <p>
        If you object to any such modifications, your sole recourse shall be to
        cease using the Website. Continued use of the Website following notice
        of any such modifications indicates you acknowledge and agree to be
        bound by the modifications. Also, please know that these Terms may be
        superseded by expressly-designated legal notices or terms located on
        particular pages of the Website. Such expressly-designated legal notices
        or terms are incorporated into these Terms and supersede the
        provision(s) of these Terms that are designated as being superseded.
      </p>
      <h2>GENERAL USE.</h2>
      <p>
        By using the Website, you agree that you are at least 18 years of age,
        or if you are under 18 years of age (a “Minor”), that you are using the
        Website with the consent of your parent or legal guardian and you have
        received your parent’s or legal guardian’s permission to use the Website
        and agree to these Terms. If you are a parent or legal guardian of a
        Minor, you agree to bind the Minor to these Terms and to fully indemnify
        and hold us harmless if the Minor breaches any of these Terms.
      </p>
      <p>
        In these Terms we are granting you a limited, personal, non-exclusive
        and non-transferable license to access the Website and display the
        Materials; your right to use the Materials is conditioned on your
        compliance with these Terms. You have no other rights in the Website,
        Services or any Materials and you may not modify, edit, copy, reproduce,
        create derivative works of, reverse engineer, alter, enhance or in any
        way exploit the Website, the Services or any of the Materials in any
        manner. If you make copies of any of the Materials then we ask that you
        be sure to keep on the copies all of our copyright and other proprietary
        notices as they appear on the Website.
      </p>
      <p>
        Unfortunately, if you breach any of these Terms, the above license will
        terminate automatically and you must immediately destroy any downloaded
        or printed Materials (and any copies thereof).
      </p>
      <h2>ORDERS AND PAYMENT.</h2>
      <p>
        You may be permitted to use the Website to order and/or pay for our
        products. To do so, you must supply certain information relevant to your
        transaction, including, without limitation, your credit card
        information, your billing address, your email and your phone number. You
        may also be asked to provide a delivery address if delivery is
        available. Once you have submitted your credit card information, we or
        our payment processor will authorize the payment and send you an email
        confirming receipt of your order. Your payment method will be charged
        when you click “Place Your Order”. You agree to pay any fees applicable
        to your use of the Website and Services, including but not limited to
        your purchase of items. We may suspend or terminate your account and/or
        access to the Website if your offered payment method cannot be
        processed. By providing a payment method, you expressly authorize us
        and/or our third party payment processor to charge the applicable fees
        on said payment method as well as taxes and other charges incurred
        thereto, all of which depend on the services you utilize and the
        products you purchase.{" "}
      </p>
      <p>
        You agree that we may charge any unpaid amounts to your provided payment
        method and/or send you a bill for such unpaid fees.
      </p>
      <p>
        All descriptions, images, features, specifications and prices of our
        products are subject to change at any time without notice. The inclusion
        of any product on the Website does not imply or warrant that such
        product will be available. We reserve the right, with or without prior
        notice, to do any one or more of the following for any reason: (i) limit
        the available quantity of or discontinue any product; (ii) impose
        conditions on the honoring of any coupon, coupon code, promotional code,
        or other similar promotion; (iii) bar you from making or completing any
        or all transactions; and (iv) refuse to provide you with any product.{" "}
      </p>
      <p>
        If you choose to have products shipped to your address or a pick up
        site, such products will be shipped in accordance with the shipping
        method you select when placing the order. You acknowledge that any
        delivery dates we may provide are non-binding estimates only and that
        you have no claim against us for delays or early deliveries. We reserve
        the right to make shipments in installments.{" "}
      </p>
      <p>
        You should inspect your package to ensure the contents arrive without
        damage, tampering or alteration. If the recipient is not home when a
        delivery arrives, the delivery person may leave the package for you at
        your door.
      </p>
      <ul>
        We are not responsible for:
        <li>
          Items delivered to incorrect addresses supplied by you or the
          recipient of the product.
        </li>
        <li>
          Delays resulting from inclement weather, natural disaster or other
          unforeseen circumstances.
        </li>
        <li>
          Delivery issues arising from the recipient not being present at the
          time of delivery at the address supplied by the you or recipient.
        </li>
        <li>
          Decreased product quality due to an incorrect delivery address
          supplied by the you or the recipient, a re-route requested by the you
          or the recipient, or a recipient’s failure to timely retrieve products
          from a pick up site.
        </li>
        <li>
          Product quality problems caused by improper handling by the recipient.
        </li>
      </ul>
      <p>
        If you are dissatisfied with any product, please contact us at:
        206-764-1000. One of our associates will respond to your query and,
        depending on the circumstances, we may provide you a replacement or a
        full or partial refund or credit of the purchase price for that product.
        We may require the return or photographic documentation of any product
        with which you are dissatisfied before we provide you a replacement,
        credit, or refund. All Sales are Final. Exceptions to this rule are at 
        Piroshky Baking Company's sole discretion.
      </p>
      <h2>ELECTRONIC COMMUNICATIONS.</h2>
      <p>
        By using the Website (or any part thereof), you consent to receiving
        electronic communications from us (including, if you have opted in, via
        text message) . These electronic communications may include notices
        about applicable fees and charges, transactional information and other
        information concerning or related to the Website. These electronic
        communications are part of your relationship with us. You agree that any
        notices, agreements, disclosures or other communications that we send
        you electronically will satisfy any legal communication requirements,
        including that such communications be in writing. Standard carrier data
        charges may apply to your use of text messaging and you are solely
        responsible for such charges.
      </p>
      <h2>PRIVACY POLICY.</h2>
      <p>
        We respect the information that you provide to us, and want to be sure
        you fully understand exactly how we use that information. So, please
        review our Privacy Policy (“Privacy Policy”) which explains everything.
      </p>
      <h2>LINKS TO THIRD-PARTY SITES.</h2>
      <p>
        The Website provides links to third-party websites. If you use these
        links, you will leave the Website. We are not obligated to review any
        third-party websites that you link to from the Website, we do not
        control any of the third-party websites, and we are not responsible for
        any of the third-party websites (or the products, services, or content
        available through any of them). Thus, unless specifically stated
        elsewhere in the Website, we do not endorse or make any representations
        about such third-party websites, any information, software, products,
        services, or materials found there or any results that may be obtained
        from using them. If you decide to access any of the third-party websites
        linked to from the Website, you do so entirely at your own risk and you
        must follow the privacy policies and terms and conditions for those
        third-party websites.
      </p>
      <h2>SUBMISSIONS.</h2>
      <p>
        Certain areas of the Website may permit you to upload or submit
        feedback, information, images, data, text, software, messages, or other
        materials (each, a "User Submission"). You agree that you are solely
        responsible for all of your User Submissions and that any such User
        Submission is considered non-confidential. Further, we do not guarantee
        that you will be able to edit or delete any User Submission you have
        submitted.
      </p>
      <ul>
        By submitting any User Submission, you are promising us that:
        <li>
          You own all rights in your User Submissions (including, without
          limitation, all rights to the reproduction and display of your User
          Submissions) or, alternatively, you have acquired all necessary rights
          in your User Submissions to enable you to grant to us the rights in
          your User Submissions as described in these Terms;
        </li>
        <li>
          You have paid and will pay in full all license fees, clearance fees,
          and other financial obligations, of any kind, arising from any use or
          commercial exploitation of your User Submissions;
        </li>
        <li>
          Your User Submissions do not infringe the copyright, trademark,
          patent, trade secret, or other intellectual property rights, privacy
          rights, or any other legal or moral rights of any third party;
        </li>
        <li>
          You voluntarily agree to waive all "moral rights" that you may have in
          your User Submission;
        </li>
        <li>
          Any information contained in your User Submission is not known by you
          to be false, inaccurate, or misleading;
        </li>
        <li>
          Your User Submission does not violate any law (including, but not
          limited to, those governing export control, consumer protection,
          unfair competition, anti-discrimination, or false advertising);
        </li>
        <li>
          Your User Submission is not, and may not reasonably be considered to
          be, defamatory, libelous, hateful, racially, ethnically, religiously,
          or otherwise biased or offensive, unlawfully threatening, or
          unlawfully harassing to any individual, partnership, or corporation,
          vulgar, pornographic, obscene, or invasive of another's privacy;
        </li>
        <li>
          You were not and will not be compensated or granted any consideration
          by any third party for submitting your User Submission;
        </li>
        <li>
          Your User Submission does not incorporate materials from a third-party
          website, or addresses, email addresses, contact information, or phone
          numbers (other than your own);
        </li>
        <li>
          Your User Submission does not contain any viruses, worms, spyware,
          adware, or other potentially damaging programs or files;
        </li>
        <li>
          Your User Submission does not contain any information that you
          consider confidential or personal; and
        </li>
        <li>
          Your User Submission does not contain or constitute any unsolicited or
          unauthorized advertising, promotional materials, junk mail, spam,
          chain letters, pyramid schemes, or any other form of unlawful
          solicitation.
        </li>{" "}
      </ul>
      <ul>
        By submitting a User Submission, you grant to us an irrevocable,
        perpetual, transferable, non-exclusive, fully-paid, worldwide,
        royalty-free license (sublicensable through multiple tiers) to:
        <li>
          Use, distribute, reproduce, modify, adapt, publish, translate,
          publicly perform, and publicly display your User Submission (or any
          modification thereto), in whole or in part, in any format or medium
          now known or later developed;{" "}
        </li>
        <li>
          Use (and permit others to use) your User Submission in any manner and
          for any purpose (including, without limitation, commercial purposes)
          that we deem appropriate in our sole discretion (including, without
          limitation, to incorporate your User Submission or any modification
          thereto, in whole or in part, into any technology, product, or
          service);
        </li>
        <li>
          Display advertisements in connection with your User Submission and to
          use your User Submission for advertising and promotional purposes.{" "}
        </li>
      </ul>
      <p>
        We may, but are not obligated to, pre-screen User Submissions or monitor
        any area of the Website through which User Submissions may be submitted.
        We are not required to host, display, or distribute any User Submissions
        on or through the Website and may remove at any time or refuse any User
        Submissions for any reason. We are not responsible for any loss, theft,
        or damage of any kind to any User Submissions. Further, you agree that
        we may freely disclose your User Submission to any third party absent
        any obligation of confidence on the part of the recipient.{" "}
      </p>
      <h2>UNAUTHORIZED ACTIVITIES.</h2>
      <p>
        To be clear, we authorize your use of the Website only for individual,
        consumer purposes (“Permitted Purposes”). Any other use of the Website
        beyond the Permitted Purposes is prohibited and, therefore, constitutes
        unauthorized use of the Website. This is because as between you and
        Piroshky Bakery, all rights in the Website and Materials remain our
        property. Unauthorized use of the Website may result in violation of
        various United States and international copyright laws. Unless you have
        written permission from us stating otherwise, you are not authorized to
        use the Website in any of the following ways (these are examples only
        and the list below is not a complete list of everything that you are not
        permitted to do):
      </p>
      <li>
        In a manner that modifies, publicly displays, publicly performs,
        reproduces or distributes any of the Website;
      </li>
      <li>
        In a manner that violates any local, state, national, foreign, or
        international statute, regulation, rule, order, treaty, or other law;
      </li>
      <li>To stalk, harass, or harm another individual;</li>
      <li>
        To impersonate any person or entity or otherwise misrepresent your
        affiliation with a person or entity;
      </li>
      <li>
        To interfere with or disrupt the Website or servers or networks
        connected to the Website;
      </li>
      <li>
        To use any data mining, robots, or similar data gathering or extraction
        methods in connection with the Website; or
      </li>
      <li>
        To attempt to gain unauthorized access to any portion of the Website or
        any other accounts, computer systems, or networks connected to the
        Website, whether through hacking, password mining, or any other means.
      </li>
      <p>
        You agree to hire attorneys to defend us if you violate these Terms and
        that violation results in a problem for us. You also agree to pay any
        damages that we may end up having to pay as a result of your violation.
        You alone are responsible for any violation of these Terms by you. We
        reserve the right to assume the exclusive defense and control of any
        matter otherwise subject to indemnification by you and, in such case,
        you agree to cooperate with our defense of such claim.
      </p>
      <h2>PROPRIETARY RIGHTS.</h2>
      <p>
        The trademarks, service marks, and logos of Piroshky Bakery (“Our
        Trademarks”) used and displayed on various parts of the Website are
        registered and unregistered trademarks or service marks of Piroshky
        Bakery. Other company, product, and service names located on the Website
        may be trademarks or service marks owned by others (the “Third-Party
        Trademarks”, and, collectively with Our Trademarks, the “Trademarks”).
        Nothing in these Terms should be construed as granting, by implication,
        estoppel, or otherwise, any license or right to use the Trademarks,
        without our prior written permission specific for each such use. Use of
        the Trademarks as part of a link to or from any site is prohibited
        unless establishment of such a link is approved in advance by us in
        writing. All goodwill generated from the use of Our Trademarks inures to
        our benefit.
      </p>
      <p>
        Unless otherwise specified in these Terms, all Materials, including the
        arrangement of them on the Website are our sole property, or the
        property of our suppliers and licensors. All rights not expressly
        granted herein are reserved. Except as otherwise required or limited by
        applicable law, any reproduction, distribution, modification,
        retransmission, or publication of any copyrighted material is strictly
        prohibited without the express written consent of the copyright owner or
        license.
      </p>
      <h2>DISCLAIMER OF WARRANTIES.</h2>
      <p>
        THE WEBSITE, MATERIALS AND SERVICES ARE PROVIDED "AS IS" AND "WITH ALL
        FAULTS", AND THE ENTIRE RISK AS TO THERE USE IS WITH YOU. WE EXPRESSLY
        DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS, IMPLIED OR STATUTORY) WITH
        RESPECT TO THE WEBSITE, MATERIALS AND SERVICES, WHICH INCLUDES BUT IS
        NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF
        INTELLECTUAL PROPERTY RIGHTS.{" "}
      </p>
      <p>
        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY
        THAT THE WEBSITE, MATERIALS AND SERVICES WILL MEET YOUR REQUIREMENTS,
        THAT YOUR USE OF THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR FREE OR THAT DEFECTS IN THE WEBSITE WILL BE CORRECTED. WE MAKE NO
        WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        WEBSITE, MATERIALS AND SERVICES, OR AS TO THE ACCURACY OR RELIABILITY OF
        ANY INFORMATION OR SERVICES OBTAINED THROUGH THE USE OF THE WEBSITE, AND
        ARE NOT RESPONSIBLE FOR THE PRODUCTS, SERVICES, ACTIONS, OR FAILURE TO
        ACT OF ANY THIRD PARTY. NO ADVICE OR INFORMATION, WHETHER ORAL OR
        WRITTEN, OBTAINED BY YOU THROUGH THE WEBSITE OR FROM US OR OUR
        SUBSIDIARIES/OTHER AFFILIATED COMPANIES SHALL CREATE ANY WARRANTY. WE
        DISCLAIM ALL EQUITABLE INDEMNITIES.
      </p>
      <h2>LIMITATION OF LIABILITY.</h2>
      <p>
        YOU ARE USING THE WEBSITE, MATERIALS AND SERVICES AT YOUR SOLE RISK. WE
        SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR
        DISPLAYING, COPYING, OR DOWNLOADING ANY MATERIALS TO OR FROM THE WEBSITE
        OR YOUR USE OF OUR SERVICES. IN NO EVENT SHALL WE BE LIABLE TO YOU FOR
        ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL,
        OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE, PROFITS, COST
        OF SUBSTITUTE GOODS AND SERVICES, USE, OR OTHER ECONOMIC ADVANTAGE)
        HOWEVER ARISING, EVEN IF WE KNOW THERE IS A POSSIBILITY OF SUCH DAMAGE.{" "}
      </p>
      <h2>LOCAL LAWS; EXPORT CONTROL.</h2>
      <p>
        We control and operate the Website and provide the Services from our
        headquarters in the United States of America and the entirety of the
        Website may not be appropriate or available for use in other locations.
        If you use the Website (or any portion thereof) outside the United
        States of America, you are solely responsible for following applicable
        local laws.
      </p>
      <h2>FEEDBACK.</h2>
      <p>
        Any submissions by you to us (e.g., comments, questions, suggestions,
        materials – collectively, “Feedback”) through any communication
        whatsoever (e.g., call, fax, email) will be treated as both
        non-confidential and non-proprietary. You hereby assign all right,
        title, and interest in, and we are free to use, without any attribution
        or compensation to you, any ideas, know-how, concepts, techniques, or
        other intellectual property and proprietary rights contained in the
        Feedback, whether or not patentable, for any purpose whatsoever,
        including but not limited to, developing, manufacturing, having
        manufactured, licensing, marketing, and selling, directly or indirectly,
        products and services using such Feedback. Where the foregoing
        assignment is prohibited by law, you hereby grant us an exclusive,
        transferable, worldwide, royalty-free, fully paid up license (including
        the right to sublicense) to use and exploit all Feedback as we may
        determine in our sole discretion. You understand and agree, however,
        that we are not obligated to use, display, reproduce, or distribute any
        such ideas, know-how, concepts, or techniques contained in the Feedback,
        and you have no right to compel such use, display, reproduction, or
        distribution.
      </p>
      <h2>DISPUTE RESOLUTION AND ARBITRATION; CLASS ACTION WAIVER.</h2>
      <strong>
        Please read the following Dispute Resolution and Arbitration; Class
        Action Waiver provision (this “Provision”) carefully. It affects your
        rights.
      </strong>
      <p>
        Please read this Provision carefully. It provides that all Disputes (as
        defined below) between you and Piroshky Bakery shall be resolved by
        binding arbitration. Arbitration is a form of private dispute resolution
        and replaces the right to go to court. In the absence of this
        arbitration agreement, you may otherwise have a right or opportunity to
        bring claims in a court, before a judge or jury, and/or to participate
        in or be represented in a case filed in court by others (including, but
        not limited to, class actions). Except as otherwise provided, entering
        into these Terms constitutes a waiver of your right to litigate claims
        and all opportunity to be heard by a judge or jury. There is no judge or
        jury in arbitration, and court review of an arbitration award is
        limited. The arbitrator must follow these Terms and can award the same
        damages and relief as a court (including attorney’s fees).
      </p>
      <p>
        For purposes of this Provision, “Piroshky Bakery” means Piroshky Baking
        Company, LLC and its parents, subsidiaries, and affiliate companies, and
        each of their respective officers, directors, employees, and agents. The
        term “Dispute” means any dispute, claim, or controversy between you and
        Piroshky Bakery regarding, arising out of or relating to any aspect of
        your relationship with Piroshky Bakery, whether based in contract,
        statute, regulation, ordinance, tort (including, but not limited to,
        fraud, misrepresentation, fraudulent inducement, or negligence), or any
        other legal or equitable cause of action or claim for relief, and
        includes the validity, enforceability or scope of this Provision (with
        the exception of the enforceability of the Class Action Waiver clause
        below). “Dispute” is to be given the broadest possible meaning that will
        be enforced, and shall include any claims against other parties relating
        to services or products provided or billed to you (such as Piroshky
        Bakery’s licensors, suppliers, dealers or third-party vendors) whenever
        you also assert claims against us in the same proceeding.
      </p>
      <p>
        YOU AND PIROSHKY BAKERY EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY
        AND ALL DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR
        BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE
        RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION RATHER THAN IN
        COURT IN ACCORDANCE WITH THIS PROVISION.
      </p>
      <h3>
        <u>Pre-Arbitration Claim Resolution</u>
      </h3>
      <p>
        For all Disputes, whether pursued in court or arbitration, you must
        first give Piroshky Bakery an opportunity to resolve the Dispute. You
        must commence this process by mailing written notification to Piroshky
        Baking Company, LLC, 309 S. Cloverdale Street, C 33, Seattle, WA 98108.
        That written notification must include (1) your name, (2) your address,
        (3) a written description of your Claim, and (4) a description of the
        specific relief you seek. If Piroshky Bakery does not resolve the
        Dispute within 45 days after it receives your written notification, you
        may pursue your Dispute in arbitration. You may pursue your Dispute in a
        court only under the circumstances described below.
      </p>
      <h3>
        <u>Exclusions from Arbitration/Right to Opt Out</u>
      </h3>
      <p>
        Notwithstanding the above, you or Piroshky Bakery may choose to pursue a
        Dispute in court and not by arbitration if (a) the Dispute qualifies, it
        may be initiated in small claims court; or (b) YOU OPT-OUT OF THESE
        ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST
        CONSENT TO THESE TERMS (the “Opt-Out Deadline”). You may opt out of this
        Provision by mailing written notification to Piroshky Baking Company,
        LLC, 309 S. Cloverdale Street, C 33, Seattle, WA 98108. Your written
        notification must include (1) your name, (2) your address, and (3) a
        clear statement that you do not wish to resolve disputes with Piroshky
        Bakery through arbitration. Your decision to opt-out of this Provision
        will have no adverse effect on your relationship with Piroshky Bakery.
        Any opt-out request received after the Opt-Out Deadline will not be
        valid and you must pursue your Dispute in arbitration or small claims
        court.
      </p>
      <h3>
        <u>Arbitration Procedures</u>
      </h3>
      <p>
        If this Provision applies and the Dispute is not resolved as provided
        above (Pre-Arbitration Claim Resolution) either you or Piroshky Bakery
        may initiate arbitration proceedings. The American Arbitration
        Association (“AAA”), www.adr.org, or JAMS, www.jamsadr.com, will
        arbitrate all Disputes, and the arbitration will be conducted before a
        single arbitrator. The arbitration shall be commenced as an individual
        arbitration only, and shall in no event be commenced as a class
        arbitration or a consolidated or representative action or arbitration.
        All issues shall be for the arbitrator to decide, including the scope of
        this Provision.
      </p>
      <p>
        For arbitration before AAA, for Disputes of less than $75,000, the AAA’s
        Supplementary Procedures for Consumer-Related Disputes will apply; for
        Disputes involving $75,000 or more, the AAA’s Commercial Arbitration
        Rules will apply. In either instance, the AAA’s Optional Rules For
        Emergency Measures Of Protection shall apply. The AAA rules are
        available at www.adr.org or by calling 1-800-778-7879. For arbitration
        before JAMS, the JAMS Comprehensive Arbitration Rules &amp; Procedures
        and the JAMS Recommended Arbitration Discovery Protocols For Domestic,
        Commercial Cases will apply. The JAMS rules are available at
        www.jamsadr.com or by calling 1-800-352-5267. This Provision governs in
        the event it conflicts with the applicable arbitration rules. Under no
        circumstances will class action or representative action procedures or
        rules apply to the arbitration.
      </p>
      <p>
        Because the Website and these Terms concern interstate commerce, the
        Federal Arbitration Act (“FAA”) governs the arbitrability of all
        Disputes. However, the arbitrator will apply applicable substantive law
        consistent with the FAA and the applicable statute of limitations or
        condition precedent to suit.
      </p>
      <p>
        Arbitration Award – The arbitrator may award on an individual basis any
        relief that would be available pursuant to applicable law, and will not
        have the power to award relief to, against or for the benefit of any
        person who is not a party to the proceeding. The arbitrator will make
        any award in writing but need not provide a statement of reasons unless
        requested by a party or if required by applicable law. Such award will
        be final and binding on the parties, except for any right of appeal
        provided by the FAA or other applicable law, and may be entered in any
        court having jurisdiction over the parties for purposes of enforcement.
      </p>
      <p>
        Location of Arbitration – You or Piroshky Bakery may initiate
        arbitration in either King County, Washington or the federal judicial
        district that includes your billing address.
      </p>
      <p>
        Payment of Arbitration Fees and Costs – Piroshky Bakery will pay all
        arbitration filing fees and AAA or JAMS hearing fees and any
        arbitrator's hearing fees, costs and expenses upon your written request
        to the arbitrator given at or before the first evidentiary hearing in
        the arbitration. You are responsible for all additional fees and costs
        that you incur in the arbitration, including, but not limited to,
        attorneys or expert witnesses. Fees and costs may be awarded as provided
        pursuant to applicable law. In addition to any rights to recover fees
        and costs under applicable law, if you provide notice and negotiate in
        good faith with Piroshky Bakery as provided in the section above titled
        “Pre-Arbitration Claim Resolution” and the arbitrator concludes that you
        are the prevailing party in the arbitration, you will be entitled to
        recover reasonable attorney’s fees and costs as determined by the
        arbitrator.
      </p>
      <h3>
        <u>Class Action Waiver</u>
      </h3>
      <p>
        Except as otherwise provided in this Provision, the arbitrator may not
        consolidate more than one person’s claims, and may not otherwise preside
        over any form of a class or representative proceeding or claims (such as
        a class action, consolidated action, representative action, or private
        attorney general action) unless both you and Piroshky Bakery
        specifically agree to do so in writing following initiation of the
        arbitration. If you choose to pursue your Dispute in court by opting out
        of the Provision, as specified above, this Class Action Waiver will not
        apply to you. Except to the extent that representative claims under
        California’s Private Attorney General Act (“PAGA”) are excluded from
        this Agreement, neither you, nor any other user of the Website can be a
        class representative, class member, or otherwise participate in a class,
        consolidated, or representative proceeding without having complied with
        the opt-out requirements above.
      </p>
      <h3>
        <u>Jury Waiver</u>
      </h3>
      <p>
        You understand and agree that by entering into these Terms you and
        Piroshky Bakery are each waiving the right to a jury trial or a trial
        before a judge in a public court. In the absence of this Provision, you
        and Piroshky Bakery might otherwise have had a right or opportunity to
        bring Disputes in a court, before a judge or jury, and/or to participate
        or be represented in a case filed in court by others (including class
        actions). Except as otherwise provided below, those rights are waived.
        Other rights that you would have if you went to court, such as the right
        to appeal and to certain types of discovery, may be more limited or may
        also be waived.{" "}
      </p>
      <h3>
        <u>Severability</u>
      </h3>
      <p>
        If any clause within this Provision (other than the Class Action Waiver
        clause above) is found to be illegal or unenforceable, that clause will
        be severed from this Provision, and the remainder of this Provision will
        be given full force and effect. If the Class Action Waiver clause is
        found to be illegal or unenforceable, this entire Provision will be
        unenforceable, and the Dispute will be decided by a court.
      </p>
      <h3>
        <u>Continuation</u>
      </h3>
      <p>
        This Provision shall survive the termination of your service with
        Piroshky Bakery or its affiliates. Notwithstanding any provision in
        these Terms to the contrary, we agree that if Piroshky Bakery makes any
        change to this Provision (other than a change to the Notice Address),
        you may reject any such change and require Piroshky Bakery to adhere to
        the present language in this Provision if a dispute between us arises.
      </p>
      <h2>General.</h2>
      <p>
        We prefer to advise you if we feel you are not complying with these
        Terms and to recommend any necessary corrective action. However, certain
        violations of these Terms, as determined by us, may result in immediate
        termination of your access to the Website without prior notice to you.
        The Federal Arbitration Act, Washington state law and applicable U.S.
        federal law, without regard to the choice or conflicts of law
        provisions, will govern these Terms. Foreign laws do not apply. The
        United Nations on Contracts for the International Sale of Goods and any
        laws based on the Uniform Computer Information Transactions Act (UCITA)
        shall not apply to these Terms. Except for Disputes subject to
        arbitration as described above, any disputes relating to these Terms or
        the Website will be heard in the courts located in King County in the
        State of Washington. If any of these Terms is found to be inconsistent
        with applicable law, then such term shall be interpreted to reflect the
        intentions of the parties, and no other terms will be modified. Our
        failure to enforce any of these Terms is not a waiver of such term.
        These Terms are the entire agreement between you and Piroshky Bakery and
        supersede all prior or contemporaneous negotiations, discussions or
        agreements between you and Piroshky Bakery about the Website. The
        proprietary rights, disclaimer of warranties, representations made by
        you, indemnities, limitations of liability and general provisions shall
        survive any termination of these Terms.
      </p>
      <h2>CALIFORNIA CONSUMER NOTICE.</h2>
      <p>
        Under California Civil Code Section 1789.3, California users are
        entitled to the following consumer rights notice: This Applications and
        Services are provided by Piroshky Baking Company, LLC, 309 S. Cloverdale
        Street, C 33, Seattle, WA 98108. If you have a question or complaint
        regarding the Website or Services, please contact Customer Service at
        cs@piroshkybakery.com. You may also contact us by writing Piroshky
        Baking Company, LLC, 309 S. Cloverdale Street, C 33, Seattle, WA 98108.
        California residents may reach the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer
        Affairs by post at 1625 North Market Blvd., Sacramento, CA 95834 or by
        telephone at (916) 445-1254 or (800) 952-5210 or Hearing Impaired at TDD
        (800) 326-2297 or TDD (916) 322-1700.
      </p>
      <h2>CONTACT US.</h2>
      <p>
        If you have any questions about these Terms or otherwise need to contact
        us for any reason, you can reach us at cs@piroshkybaker.com or by phone
        at 206-764-1000.
      </p>
    </div>
  );
};
